<template>
  <div>
    <b-alert
      :show="form.errors && form.errors.length > 0"
      variant="light"
      class="alert red lighten-4"
      ref="alert"
    >
      <div class="alert-icon">
        <i class="flaticon-warning kt-font-danger"></i>
      </div>
      <div class="alert-text">
        <div v-for="err in form.errors" :key="err">
          {{ err }}
        </div>
      </div>
    </b-alert>
    <div class="row">
      <div class="col-md-12">
        <KTPortlet v-bind:title="title">
          <template v-slot:body>
            <v-form
              ref="form"
              @submit.prevent="submitForm"
              v-model="form.valid"
              lazy-validation
            >
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="form.title"
                    :disabled="form.loading"
                    :counter="200"
                    :label="$t('TERM_AND_PRIVACY.ADD_EDIT.TITLE')"
                    :rules="form.titleRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="form.version"
                    :disabled="form.loading"
                    :counter="100"
                    :label="$t('TERM_AND_PRIVACY.ADD_EDIT.VERSION')"
                    :rules="form.versionRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="form.type"
                    :disabled="form.loading"
                    :items="form.typeItems"
                    :loading="form.typeLoading"
                    :search-input.sync="form.typeSearch"
                    hide-no-data
                    hide-selected
                    item-text="text"
                    item-value="value"
                    :label="$t('TERM_AND_PRIVACY.ADD_EDIT.TYPE')"
                    :rules="form.typeRules"
                    return-object
                    clearable
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="6">
                  <v-autocomplete
                    v-model="form.requireApprove"
                    :disabled="form.loading"
                    :items="form.requireApproveItems"
                    :loading="form.typeLoading"
                    :search-input.sync="form.requireApproveSearch"
                    hide-no-data
                    hide-selected
                    item-text="text"
                    item-value="value"
                    :label="$t('TERM_AND_PRIVACY.ADD_EDIT.REQUIRE_APPROVE')"
                    :rules="form.requireApproveRules"
                    return-object
                    clearable
                  ></v-autocomplete>
                </v-col>

                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="form.headerTH"
                    :disabled="form.loading"
                    :counter="200"
                    :label="$t('TERM_AND_PRIVACY.ADD_EDIT.HEADER_TH')"
                    :rules="form.headerTHRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="form.headerEN"
                    :disabled="form.loading"
                    :counter="200"
                    :label="$t('TERM_AND_PRIVACY.ADD_EDIT.HEADER_EN')"
                    :rules="form.headerENRules"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                   <label>{{ $t('TERM_AND_PRIVACY.ADD_EDIT.DESCRIPTION_TH') }}</label>
                    <editor
                    api-key="no-api-key"
                    :init="{
                        height: 500,
                        menubar: false,
                        content_css : 'dark',
                        plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                        'undo redo | formatselect | fontsizeselect bold italic forecolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help'
                    
                    }"
                    v-model="form.descriptionTH"
                    :disabled="form.loading"
                    required
                    />
                </v-col>

                <v-col cols="12" md="12">
                  <label>{{ $t('TERM_AND_PRIVACY.ADD_EDIT.DESCRIPTION_EN') }}</label>
                    <editor
                    api-key="no-api-key"
                    :init="{
                        height: 500,
                        menubar: false,
                        content_css : 'dark',
                        plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                        'undo redo | formatselect | fontsizeselect bold italic forecolor | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent | removeformat | help'
                    
                    }"
                    v-model="form.descriptionEN"
                    :disabled="form.loading"
                    required
                    />
                </v-col>

                <v-col cols="12" md="12">
                  <v-checkbox
                    v-model="form.inActiveStatus"
                    :disabled="form.loading"
                    :label="$t('TERM_AND_PRIVACY.ADD_EDIT.IN_ACTIVE_STATUS')"
                    required
                  ></v-checkbox>
                </v-col>


              </v-row>
              <v-row>
                <div class="col-12">
                  <v-btn
                    :disabled="!form.valid || form.loading"
                    color="success"
                    class="mr-4"
                    tile
                    type="submit"
                  >
                    <v-icon left>la-save</v-icon>
                    {{ $t("SHARED.SAVE_BUTTON") }}
                  </v-btn>
                  <v-btn
                    :disabled="form.loading"
                    color="default"
                    class="mr-4"
                    type="reset"
                    tile
                    @click.prevent="resetForm"
                  >
                    <v-icon left>mdi-eraser</v-icon>
                    {{ $t("SHARED.RESET_BUTTON") }}
                  </v-btn>
                </div>
              </v-row>

              <v-dialog v-model="form.dialog" persistent max-width="300">
                <v-card>
                  <v-card-title class="headline">
                    {{
                      $t("TERM_AND_PRIVACY.ADD_EDIT.SUCCESS_DIALOG_HEADER")
                    }}</v-card-title
                  >
                  <v-card-text>
                    {{ $t("TERM_AND_PRIVACY.ADD_EDIT.ADD_SUCCESS_DIALOG_TEXT") }}
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="closeDialog">{{
                      $t("SHARED.CLOSE_BUTTON")
                    }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog
                v-model="form.loading"
                persistent
                hide-overlay
                width="300"
              >
                <v-card>
                  <v-card-title class="headline">
                    {{ $t("SHARED.PLEASE_WAIT") }}</v-card-title
                  >
                  <v-card-text>
                    <p>
                      {{ $t("SHARED.PROCESSING") }}
                    </p>
                    <v-progress-linear
                      indeterminate
                      color="amber lighten-3"
                      class="mb-3"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-form>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';
import ApiService from "@/common/api.service";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";

export default {
  components: {
    KTPortlet,
    'editor': Editor
  },
  data() {
    return {
      form: {
        valid: true,
        dialog: false,
        loading: false,
        errors: [],

        title: "",
        version: "",
        headerTH: null,
        headerEN: null,
        descriptionTH: null,
        descriptionEN: null,
        type: null,
        requireApprove: null,
        
        inActiveStatus: true,
        titleRules: [
          (v) => 
          !!v || this.$t("TERM_AND_PRIVACY.ADD_EDIT.TITLE_VALIDATION"),
          (v) =>
            (v && v.length <= 200) ||
            this.$t("TERM_AND_PRIVACY.ADD_EDIT.TITLE_COUNTER"),
        ],
        headerTHRules: [
          (v) => 
          !!v || this.$t("TERM_AND_PRIVACY.ADD_EDIT.HEADER_TH_VALIDATION"),
          (v) =>
            (v && v.length <= 200) ||
            this.$t("TERM_AND_PRIVACY.ADD_EDIT.HEADER_TH_COUNTER"),
        ],
        headerENRules: [
          (v) => 
          !!v || this.$t("TERM_AND_PRIVACY.ADD_EDIT.HEADER_EN_VALIDATION"),
          (v) =>
            (v && v.length <= 200) ||
            this.$t("TERM_AND_PRIVACY.ADD_EDIT.HEADER_EN_COUNTER"),
        ],
        descriptionTHRules: [
          (v) =>
            !!v || this.$t("TERM_AND_PRIVACY.ADD_EDIT.DESCRIPTION_TH_VALIDATION"),
        ],
        descriptionENRules: [
          (v) =>
            !!v || this.$t("TERM_AND_PRIVACY.ADD_EDIT.DESCRIPTION_EN_VALIDATION"),
        ],
        versionRules: [
          (v) => 
          !!v || this.$t("TERM_AND_PRIVACY.ADD_EDIT.VERSION_VALIDATION"),
          (v) =>
            (v && v.length <= 100) ||
            this.$t("TERM_AND_PRIVACY.ADD_EDIT.VERSION_COUNTER"),
        ],
        typeSearch: "",
        typeItems: [],
        typeRules: [
          (v) => !!v || this.$t("TERM_AND_PRIVACY.ADD_EDIT.TYPE_VALIDATION"),
        ],
        requireApproveSearch: "",
        requireApproveItems: [],
        requireApproveRules: [
          (v) => !!v || this.$t("TERM_AND_PRIVACY.ADD_EDIT.REQUIRE_APPROVE_VALIDATION"),
        ],
      },
    };
  },
  computed: {
    title() {
      return this.$t("MENU.TERM_AND_PRIVACY.ADD");
    }
  },
  watch: {
    "form.typeSearch": {
      handler() {
        this.getTypeFromApi().then((data) => {
          this.form.typeItems = data;
        });
      },
    },
    "form.requireApproveSearch": {
      handler(){
        this.form.requireApproveItems = [{
          value : false, 
          text : "ตกลงและยกเลิก",
        },{
          value : true, 
          text : "ตกลงเท่านั้น",
        }];
      }
    }
  },
  methods: {
    submitForm() {
      this.form.errors = [];

      if(this.form.descriptionTH == null || this.form.descriptionTH == ""){
        this.form.errors.push(
          this.$t("TERM_AND_PRIVACY.ADD_EDIT.DESCRIPTION_TH_VALIDATION")
        );
      }

      if(this.form.descriptionEN == null || this.form.descriptionEN == ""){
        this.form.errors.push(
          this.$t("TERM_AND_PRIVACY.ADD_EDIT.DESCRIPTION_EN_VALIDATION")
        );
      }

      if(this.$refs.form.validate() && this.form.errors.length == 0)
      {
        this.postDataToApi();
      }
    },
    resetForm() {
      this.form.descriptionTH = "";
      this.form.descriptionEN = "";
      this.$refs.form.reset();
    },
    postDataToApi() {
      // return
      this.form.loading = true;
      this.form.errors = [];

      ApiService.setHeader();
      ApiService.post("/Api/TermandPrivacy/Add", {
        Title: this.form.title,
        Type: this.form.type.value,
        Version: this.form.version,
        HeaderTH: this.form.headerTH,
        HeaderEN: this.form.headerEN,
        RequireApprove: this.form.requireApprove.value,
        DescriptionTH: this.form.descriptionTH,
        DescriptionEN: this.form.descriptionEN,
        InActiveStatus: !this.form.inActiveStatus
      })
        .then(({ data }) => {
          if (data.status) {
            // close dialog
            this.form.dialog = true;
          } else {
            this.form.dialog = false;
            this.form.loading = false;
            this.form.errors.push(!!data.message || "Unknow error occurs");
            this.$vuetify.goTo(this.$refs.alert, {
              duration: 1000,
              easing: "easeInOutCubic",
              offset: -20,
            });
          }
        })
        .catch(({ response }) => {
          if (response.data) {
            this.form.errors.push(response.data.message);
          } else {
            this.form.errors.push("Unknow error occurs");
          }
          this.$vuetify.goTo(this.$refs.alert, {
            duration: 1000,
            easing: "easeInOutCubic",
            offset: -20,
          });
          this.form.dialog = false;
          this.form.loading = false;
        });
    },
    closeDialog() {
      // not close but redirect to search page
      this.$router.push({ name: "SearchTermandPrivacy" });
    },
    getTypeFromApi() {
      return new Promise((resolve) => {
        ApiService.setHeader();
        ApiService.post("/Api/TermandPrivacy/Select2TermandPrivacyType", {
          query: null,
        })
          .then(({ data }) => {
            resolve(data);
          })
          .finally(() => {
            this.form.typeLoading = false;
          });
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("MENU.TERM_AND_PRIVACY.SECTION"),
        route: "/TermandPrivacy",
      },
      { title: this.$t("MENU.TERM_AND_PRIVACY.ADD") },
    ]);
  },
};
</script>

<style lang="scss" scoped></style>
